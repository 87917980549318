<template>
  <div class="card" :style="{ '--order-status-color': order.orderStatus.colour }">
    <div class="card-content">
      <div class="w-full flex justify-between items-center md-2">
        <div class="text-md font-bold">
          {{ order.reference }}
        </div>
        <div>
          <el-dropdown trigger="click" @command="actions">
            <i class="bx bx-dots-vertical-rounded" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="edit" v-if="$store.state.account.canEditOrders">
                <i class="bx bx-edit"></i> Editar
              </el-dropdown-item>
              <el-dropdown-item command="edit" v-if="!$store.state.account.canEditOrders">
                <i class="bx bx-low-vision"></i> Visualizar
              </el-dropdown-item>
              <el-dropdown-item command="print">
                <i class="bx bx-printer"></i> Imprimir
              </el-dropdown-item>
              <el-dropdown-item command="delete" v-if="$store.state.account.canEditOrders">
                <i class="bx bx-trash"></i> Eliminar
              </el-dropdown-item>
              <el-dropdown-item command="notes">
                <i class="bx bx-note"></i> Notas
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="w-full flex justify-between items-center mb-2">
        <div class="text-sm text-gray-700">
          Creada en:
        </div>
        <div class="text-right text-sm border rounded-sm">
          <div class="p-0.5">{{ dateFormat(order.createdAt) }}</div>
        </div>
      </div>

      <div class="w-full flex justify-between items-center mb-2">
        <div class="text-sm">
          {{ order.client.fullName }}
        </div>
        <div class="avatar-container">
          <div class="avatar">
            {{ initials }}
          </div>
        </div>
      </div>

      <div class="w-full flex justify-between items-center mb-2">
        <el-select
          :value="order.orderStatus.name"
          @change="updateStatus($event)"
          v-loading.fullscreen.lock="loading"
        >
          <div
            slot="prefix"
            class='mt-2 text-xl rounded-sm badge'
            :style="{ 'background-color': order.orderStatus.colour }"
          />
          <el-option
            v-for="status in filteredStatuses"
            :key="status.id"
            :value="status.id"
            :label="status.name">
            <div style="display: inline-flex;">
              <div
                class="mt-2 text-xl rounded-sm badge"
                :style="{ 'background-color': status.color }" />
              <div>{{ status.name }}</div>
            </div>
          </el-option>
        </el-select>
      </div>

      <div class="w-full flex justify-between items-center mb-2">
        <div class="text-sm">
          {{ PriorityTypeEnumDict[order.priority] }}
        </div>
        <div class="text-sm">
          {{ firstDeviceModel || firstProduct }}
        </div>
      </div>

      <div class="w-full flex justify-between items-center mb-2" v-if="order.dueDate">
        <div class="text-sm text-gray-700">
          Compromiso:
        </div>
        <div class="text-right text-sm border rounded-sm">
          <div class="p-0.5">{{ dateFormat(order.dueDate) }}</div>
        </div>
      </div>

      <div class="w-full flex justify-between items-center mb-2" v-if="order.finalizedStatusAt">
        <div class="text-sm text-gray-700">
          Finalizada en:
        </div>
        <div class="text-right text-sm border rounded-sm">
          <div class="p-0.5">{{ dateFormat(order.finalizedStatusAt) }}</div>
        </div>
      </div>

      <div class="w-full items-center mb-2">
        <div class="text-md text-center">
          <div>Total: </div>
          <div class="font-bold">
            {{ moneyFormat(order.totalAmount) }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style scoped>
.card {
  width: 180px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
  margin: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
  background-color: var(--order-status-color, #DCDCDC);
}
.card-content {
  padding: 16px;
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3490dc;
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.badge {
  margin: 12px 4px;
  width: 12px;
  height: 12px;
}
</style>
<script>
import OrderNotesManagerService from '@/components/dialogs/OrderNotesManager/service';
import { Whatsapp, getCountryCode } from '@/utils/phones';
import { PriorityTypeEnumDict } from '@/constants';
import numbers from '@/utils/numbers';
import { getRestrictedStatuses } from '@/helpers/order-status.helper';
import OrderService from "@/services/OrderService";

export default {
  name: 'OrderCard',
  props: {
    order: {
      type: Object,
      default: () => ({
        id: {
          type: String,
          default: () => null,
        },
        reference: {
          type: String,
          default: () => null,
        },
        client: {
          type: Object,
          default: () => ({
            fullName: {
              type: String,
              default: () => null,
            },
            phoneCountryCodeId: {
              type: String,
              default: () => null,
            },
            phone: {
              type: String,
              default: () => null,
            },
          }),
        },
        orderStatus: {
          type: Object,
          default: () => ({
            name: {
              type: String,
              defaut: () => null,
            },
            colour: {
              type: String,
              default: () => '#000000',
            },
          }),
        },
        dueDate: {
          type: String,
          default: () => null,
        },
        finalizedStatusAt: {
          type: String,
          default: () => null,
        },
        createdAt: {
          type: String,
          default: () => null,
        },
        totalAmount: {
          type: Number,
          default: () => 0,
        },
        orderDevices: {
          type: Array,
          default: () => [], // device.deviceModel | device.deviceBrand
        },
        orderProducts: {
          type: Array,
          default: () => [], // null?? should be name
        },
        priority: {
          type: Number,
          default: () => null,
        },
        assignedToAccount: {
          type: Object,
          default: () => ({
            fullname: {
              type: String,
              default: () => null,
            },
          }),
        },
        images: {
          type: Array,
          default: () => [], // string[]
        },
      }),
    },
  },
  computed: {
    images() {
      return this.order.images.map((image) => `${process.env.VUE_APP_S3_BUCKET}/${image}`);
    },
    initials() {
      if (!this.order.assignedToAccount?.fullname) {
        return 'NA';
      }
      const words = this.order.assignedToAccount.fullname.split(' ');
      return words
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
    },
    firstDeviceModel() {
      if (this.order.orderDevices.length === 0) {
        return null;
      }
      return this.order.orderDevices[0].deviceModel;
    },
    firstProduct() {
      if (!this.firstDeviceModel) {
        if (this.order.orderProducts?.length === 0) {
          return null;
        }
        return this.order.orderProducts[0].name;
      }
      return null;
    },
    restrictedStatuses() {
      const {
        InProgress,
        ToBeDelivered
      } = getRestrictedStatuses(this.$store.state.catalogs.order.statuses);

      return {
        InProgress,
        ToBeDelivered
      };
    },
    filteredStatuses() {
      // return active statuses
      return this.$store.state.catalogs.order.statuses.filter((status) => status.isActive);
    },
  },
  methods: {
    dateFormat(date) {
      const format = this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(date).format(format);
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    actions(action) {
      switch(action) {
        case 'edit':
          this.$router.push({ name: 'orders.read', params: { id: this.order.id }});
          break;
        case 'print':
          this.$router.push({ name: 'invoices.default', params: { id: this.order.id }});
          break;
        case 'delete':
          if (!this.$store.state.account.canEditOrders) {
            return;
          }
          this.$confirm(`¿Está seguro que desea eliminar la orden ${this.order.reference}?`, {
            type: 'warning',
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancelar',
          }).then(async () => {
            this.$emit('onDeleteOrder', this.order.id);
          });
          break;
        case 'notes':
          OrderNotesManagerService.show(this.order.id);
          break;
        default:
          break;
      }
    },
    async updateStatus(status) {
      this.whatsapp(status);
      this.$emit('onStatusUpdate', { orderId: this.order.id, status });
    },
    whatsapp(status) {
      const message = `¿Deseas notificar al cliente ${this.order.client.fullName} por WhatsApp?`;
      const options = { type: 'warning', confirmButtonText: 'SI', cancelButtonText: 'NO' };
      const countryCode = getCountryCode(
        this.$store.state.catalogs.flags,
        this.order.client.phoneCountryCodeId,
      );
      if (!countryCode) {
        this.$toastr.e('El Cliente debe tener registrado el Código de Area');
        return;
      }
      switch (status) {
        case this.restrictedStatuses.InProgress.id:
          this.$confirm(message, options).then(async () => {
            Whatsapp({
              code: countryCode,
              phone: this.order.client.phone,
              text: `Tu orden ${this.order.reference} ya se está trabajando`,
            });
            await OrderService.addHistoryWhatsapp({
              orderId: this.order.id,
              message: `Tu orden ${this.order.reference} ya se está trabajando`
            });
          });
          break;
        case this.restrictedStatuses.ToBeDelivered.id:
          this.$confirm(message, options).then(async () => {
            Whatsapp({
              code: countryCode,
              phone: this.order.client.phone,
              text: `Hola tu orden ${this.order.reference} está lista para recogerse`,
            });
            await OrderService.addHistoryWhatsapp({
              orderId: this.order.id,
              message: `Hola tu orden ${this.order.reference} está lista para recogerse`
            });
          });
          break;
        default:
      }
    },
  },
  data() {
    return {
      loading: false,
      PriorityTypeEnumDict
    }
  }
}
</script>
